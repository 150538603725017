/* WelcomeMessage.css */
.welcome-message {
    text-align: center;
    margin-top: 50px;
    font-family: Arial, sans-serif;
  }
  
  .welcome-message h1 {
    font-size: 36px;
    color: #333;
  }
  
  .welcome-message p {
    font-size: 20px;
    color: #666;
    margin-top: 20px;
  }
  